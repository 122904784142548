/* $(document).ready(function () {
  $(window).scroll(function () {
    if ($(document).scrollTop() < 600 && ((window.location.pathname.includes('home.html')))) {
      $(".fixed-top").css("background-color", " transparent")
      
    } else {
      $(".fixed-top").css("background-color", "#ff6c00");
      $(".fixed-top").css("opacity", "0.9");
    }
  })
}); */
initialEle = '';
selectedMbleEle = '';
fromMbleView = false;

$(document).ready(function () {
  $('.mob-logo-button').on('click', function () {
    $('.animated-icon').toggleClass('open');
    $(".fixed-top").css("background-color", "#ff6c00");
  });
});

function closeMenu() {
  var _opened = $(".navbar-toggler").attr('aria-expanded');
  if (_opened === 'true') {
    //$('.animated-icon').toggleClass('open');
    //$(".fixed-top").css("background-color", "#ff6c00");
    $("button.navbar-toggler").click();
    $('.animated-icon').removeClass('open');
  }
}
function submitBtn() {
  var popup = document.getElementById("myPopup");
  popup.classList.remove('fade-out');
  popup.classList.add("show");
  setTimeout(function () {
    popup.classList.add('fade-out')
  }, 5000)
}
function setTransparentNavbar() {
  let selectedEle = document.getElementsByClassName('nav-link active')[0].outerText;
  let homeDiv = document.getElementById('homepage-content')
  let imageContentAboutUS = document.getElementById('image-content-aboutUs')
  let textContentAboutUS = document.getElementById('text-content-aboutUs')
  let productContent = document.getElementById('product-content')
  if((!fromMbleView && initialEle !== selectedEle) || (fromMbleView && initialEle !== selectedMbleEle)){
    document.getElementById('scrollSec').style.display = 'none';
    homeDiv && homeDiv.classList.remove('animate', 'slide', 'delay-2')
    imageContentAboutUS && imageContentAboutUS.classList.remove('animate-aboutus', 'image-effect')
    textContentAboutUS && textContentAboutUS.classList.remove('animate-aboutus', 'txt-effect')
    productContent && productContent.classList.remove('animate-prod', 'prod-box', 'delay-1')
  }
  
  if ( $(document).scrollTop() < 600 || (fromMbleView && selectedMbleEle === 'Home') || (!fromMbleView && selectedEle === 'Home')) {
    $(".fixed-top").css("background-color", " transparent")
    
  } else {
    $(".fixed-top").css("background-color", "#ff6c00");
  }  
  scrollFunction();  
  
  let scrollLen = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
  if((!fromMbleView && initialEle !== selectedEle) || (fromMbleView && initialEle !== selectedMbleEle)){
    if(!fromMbleView) initialEle = selectedEle;
    if(fromMbleView) initialEle = selectedMbleEle;
    if(selectedEle === 'Home' && homeDiv) {
      homeDiv.classList.add('animate', 'slide', 'delay-2');
      setTimeout(() => {
        document.getElementById('scrollSec').style.display = 'block';
      },4000)
    }
    if((fromMbleView && selectedMbleEle === 'Home') ){
      homeDiv.classList.add('animate', 'slide', 'delay-2');
      setTimeout(() => {
        document.getElementById('scrollSec').style.display = 'block';
      },4000)
    }
    (selectedEle === 'About Us' || selectedMbleEle === 'About Us') && imageContentAboutUS && imageContentAboutUS.classList.add('animate-aboutus', 'image-effect')
    (selectedEle === 'About Us' || selectedMbleEle === 'About Us') && textContentAboutUS && textContentAboutUS.classList.add('animate-aboutus', 'txt-effect')
    (selectedEle === 'Product' || selectedMbleEle === 'Product') && productContent && productContent.classList.add('animate-prod', 'prod-box', 'delay-1')
  }
  fromMbleView = false;
  
}
window.onscroll = setActiveMenu;
window.onload = setTransparentNavbar;




function scrollFunction() {
  mybutton = document.getElementById("scrollBtn");
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    mybutton ? mybutton.style.display = "block" : '';
  } else {
    mybutton ? mybutton.style.display = "none" : '';
  }
} 

function scrolltopFunction() {
  document.body.scrollTop = 0; 
  document.documentElement.scrollTop = 0; 
}

$(function() {
  $('a[href*=#]').on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: $($(this).attr('href')).offset().top}, 500, 'linear');
  });
});

function setActiveMenu(event, fromScroll){
  if(fromScroll){
    document.getElementsByClassName('nav-link active')[0].classList.remove('active');
    document.getElementsByClassName('nav-item mble-view active')[0].classList.remove('active');
    document.getElementById('aboutUsLink').classList.add('active');
    document.getElementById('li_mble_aboutus').classList.add('active');
  }else if(!fromScroll && event.target.outerText && document.getElementsByClassName('nav-link active')[0].outerText !== event.target.outerText){
    event && event.target && event.target.id && document.getElementsByClassName('nav-link active')[0].classList.remove('active');
    event && event.target && event.target.id && document.getElementById(event.target.id).classList.add('active');
    
  }
  
  setTransparentNavbar();  
}

function setMobileHeaderNavBar(event){
 selectedMbleEle = event.target.outerText;
 fromMbleView = true;
 document.getElementsByClassName('nav-item mble-view active')[0].classList.remove('active');
 document.getElementById('li_'+event.target.id).classList.add('active');
}

